<template>
  <div class="jinlinOnlineForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item
        label="任务名称"
        :rules="[
          { required: true, message: '请输入任务名称', trigger: 'blur' },
        ]"
        prop="assignmentName"
      >
        <v-input
          placeholder="请输入活动名称"
          v-model="form.assignmentName"
          :width="250"
          :maxlength="50"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="任务地点"
        :rules="[
          { required: true, message: '请输入任务地点', trigger: 'blur' },
        ]"
        prop="assignmentAddress"
      >
        <v-input
          placeholder="请输入任务地点"
          v-model="form.assignmentAddress"
          :width="250"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="认领限制人数"
        :rules="[
          { required: true, message: '请输入认领限制人数', trigger: 'blur' },
        ]"
        prop="confinePersons"
      >
        <v-input-number
          v-model="form.confinePersons"
          :min="form.applyPersons || 1"
          :width="150"
          :controls="true"
        ></v-input-number>
      </el-form-item>
      <el-form-item
        label="预估金额（元）"
        :rules="[
          { required: true, message: '请输入预估金额（元）', trigger: 'blur' },
        ]"
        prop="estimatePrice"
      >
        <v-input
          placeholder="请输入预估金额（元）"
          v-model="form.estimatePrice"
          type="number"
          :width="250"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="添加任务内容"
        :rules="[
          { required: true, message: '请输入任务内容', trigger: 'blur' },
        ]"
        prop="assignmentContent"
      >
        <v-input
          placeholder="请输入任务内容"
          v-model="form.assignmentContent"
          type="textarea"
          :width="300"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="上传照片"
        :rules="[
          {
            required: true,
            message: '请上传照片',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="picture"
      >
        <v-upload :limit="1" :imgUrls.sync="form.picture" />
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import { jinlinDetailUrl, addJinlinUrl } from "./api.js";
import {} from "./map.js";
export default {
  name: "jinlinOnlineForm",
  data() {
    return {
      submitConfig: {
        queryUrl: jinlinDetailUrl,
        submitUrl: addJinlinUrl,
      },

      form: {
        id: "",
        applyPersons: "",
        assignmentName: "",
        assignmentAddress: "",
        confinePersons: "",
        estimatePrice: "",
        assignmentContent: "",
        picture: "",
      },
    };
  },
  async mounted() {
    const { id } = this.$route.query;
    if (id !== undefined) {
      this.$refs.formPanel.getData({ id });
    } else {
    }
    this.$setBreadList(id ? "编辑" : "新增");
  },
  computed: {},
  watch: {},
  methods: {
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
    },
    submitBefore() {
      return true;
    },
    submitSuccess() {
      return true;
    },
  },
};
</script>
<style lang="less" scoped>
.jinlinOnlineForm {
  box-sizing: border-box;
  height: 100%;
  /deep/ .el-form-item__label {
    width: 160px !important;
  }
  /deep/ .el-textarea {
    height: 150px !important;
    textarea {
      height: 100%;
    }
  }
}
</style>
