var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jinlinOnlineForm"},[_c('form-panel',_vm._b({ref:"formPanel",attrs:{"form":_vm.form,"submitBefore":_vm.submitBefore,"submitSuccess":_vm.submitSuccess},on:{"update":_vm.update}},'form-panel',_vm.submitConfig,false),[_c('el-form-item',{attrs:{"label":"任务名称","rules":[
        { required: true, message: '请输入任务名称', trigger: 'blur' },
      ],"prop":"assignmentName"}},[_c('v-input',{attrs:{"placeholder":"请输入活动名称","width":250,"maxlength":50},model:{value:(_vm.form.assignmentName),callback:function ($$v) {_vm.$set(_vm.form, "assignmentName", $$v)},expression:"form.assignmentName"}})],1),_c('el-form-item',{attrs:{"label":"任务地点","rules":[
        { required: true, message: '请输入任务地点', trigger: 'blur' },
      ],"prop":"assignmentAddress"}},[_c('v-input',{attrs:{"placeholder":"请输入任务地点","width":250},model:{value:(_vm.form.assignmentAddress),callback:function ($$v) {_vm.$set(_vm.form, "assignmentAddress", $$v)},expression:"form.assignmentAddress"}})],1),_c('el-form-item',{attrs:{"label":"认领限制人数","rules":[
        { required: true, message: '请输入认领限制人数', trigger: 'blur' },
      ],"prop":"confinePersons"}},[_c('v-input-number',{attrs:{"min":_vm.form.applyPersons || 1,"width":150,"controls":true},model:{value:(_vm.form.confinePersons),callback:function ($$v) {_vm.$set(_vm.form, "confinePersons", $$v)},expression:"form.confinePersons"}})],1),_c('el-form-item',{attrs:{"label":"预估金额（元）","rules":[
        { required: true, message: '请输入预估金额（元）', trigger: 'blur' },
      ],"prop":"estimatePrice"}},[_c('v-input',{attrs:{"placeholder":"请输入预估金额（元）","type":"number","width":250},model:{value:(_vm.form.estimatePrice),callback:function ($$v) {_vm.$set(_vm.form, "estimatePrice", $$v)},expression:"form.estimatePrice"}})],1),_c('el-form-item',{attrs:{"label":"添加任务内容","rules":[
        { required: true, message: '请输入任务内容', trigger: 'blur' },
      ],"prop":"assignmentContent"}},[_c('v-input',{attrs:{"placeholder":"请输入任务内容","type":"textarea","width":300},model:{value:(_vm.form.assignmentContent),callback:function ($$v) {_vm.$set(_vm.form, "assignmentContent", $$v)},expression:"form.assignmentContent"}})],1),_c('el-form-item',{attrs:{"label":"上传照片","rules":[
        {
          required: true,
          message: '请上传照片',
          trigger: ['change', 'blur'],
        },
      ],"prop":"picture"}},[_c('v-upload',{attrs:{"limit":1,"imgUrls":_vm.form.picture},on:{"update:imgUrls":function($event){return _vm.$set(_vm.form, "picture", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "picture", $event)}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }